import { render, staticRenderFns } from "./BuySettlement.vue?vue&type=template&id=13090797&scoped=true&"
import script from "./BuySettlement.vue?vue&type=script&lang=js&"
export * from "./BuySettlement.vue?vue&type=script&lang=js&"
import style0 from "./BuySettlement.vue?vue&type=style&index=0&id=13090797&scoped=true&lang=css&"
import style1 from "./BuySettlement.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13090797",
  null
  
)

export default component.exports